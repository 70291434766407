<template>
    <bb-offer-thank-you-page
        :form-props="{
            uppercaseButtons: true,
            submitText: $t('negativeAnswer.backToSelfService')
        }"
        @submit="redirect"
    >
        <bb-hero
            slot="header"
            class="w-100"
            bleed="all"
            :title="$t('negativeAnswer.pageTitle', { firstName: negativePage.applicant.firstName })"
        />

        <p class="m-b-20 smallest">
            {{ $t('negativeAnswer.message.line1') }}
        </p>
        <p class="smallest">
            {{ $t('negativeAnswer.message.reasonHeader') }}
        </p>
        <bb-list-item :title="$t(`negativeAnswer.reason.${negativePage.rejectionReason}`)"
                      :title-uppercase="false"
                      class="m-y-5"
                      bullet
                      condensed
        />
        <p class="m-b-10 smallest">
            {{ $t('negativeAnswer.message.line2') }}
        </p>
        <p class="m-t-10 smallest">
            {{ $t('negativeAnswer.message.line3') }}
        </p>
        <p
            class="m-t-10 smallest"
            v-html="$t('negativeAnswer.message.line4')"
        >
        </p>
    </bb-offer-thank-you-page>
</template>

<script>
  import { BbOfferThankYouPage,
           BbHero,
           BbListItem } from '@bigbank/interface-components'
  import { mapGetters } from 'vuex';
  import LinksMixin from '../../mixins/LinksMixin'

  export default {
      name: 'negative-page',

      mixins: [
          LinksMixin,
      ],

      components: {
          BbOfferThankYouPage,
          BbHero,
          BbListItem,
      },

      computed: { ...mapGetters('processStep', ['negativePage']) },

      methods: {
          redirect() {
              window.location = this.getLinks.selfService;
          },
      },
  }
</script>
